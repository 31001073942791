var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ContextTitle", {
        attrs: { title: "Create Store", passedActions: [] },
      }),
      _c(
        "v-container",
        { staticClass: "align" },
        [
          _c(
            "v-row",
            { staticClass: "settings-container" },
            [
              _c("SettingsForm", {
                attrs: { groups: _vm.settingsGroups, values: _vm.values },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }