<template>
  <div>
    <ContextTitle title="Create Store" :passedActions="[]" />

    <v-container class="align">
      <v-row  class="settings-container">
        <SettingsForm :groups="settingsGroups" :values="values" />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ContextTitle from "../components/context_title.vue";
import SettingsForm from "../components/storesettings/settings_form.vue";

export default {
    name: "CreateStoreRequest",
    components: {
        ContextTitle,
        SettingsForm
    },
    data() {
        return {
            titleBarActions: [
                // {
                //     type: 'edit',
                //     display: 'Edit',
                //     run: () => {
                //         const { params } = this.$route
                //         this.$router.push({name: 'store-pos-settings-edit', params})
                //     },
                // },
            ],
            hmsHostFields: [
                {
                    key: "hmshost_subscriptionKey",
                    display: "HMS Host",
                    type: "singleline",
                    placeholder: "Subscription Key"
                },
                {
                    key: "hmshost_clientID",
                    type: "singleline",
                    placeholder: "Client ID"
                },
                {
                    key: "hmshost_username",
                    type: "singleline",
                    placeholder: "Username"
                },
                {
                    key: "hmshost_passkey",
                    type: "singleline",
                    placeholder: "Passkey"
                },
                {
                    key: "hmshost_revenueCenterID",
                    type: "singleline",
                    placeholder: "Revenue Center ID"
                },
                {
                    key: "hmshost_propertyID",
                    type: "singleline",
                    placeholder: "Property ID"
                },
                {
                    key: "hmshost_baseURL",
                    type: "singleline",
                    placeholder: "Base URL"
                },
                {
                    key: "hmshost_offlineURL",
                    type: "singleline",
                    placeholder: "Offline URL"
                },
                {
                    key: "hmshost_tenderType",
                    type: "singleline",
                    placeholder: "Tender Type"
                },
                {
                    key: "hmshost_orderTypeIDReference",
                    type: "singleline",
                    placeholder: "Order Type ID Reference"
                }
            ],
            agilysisFields: [
                {
                    display: "Agilysis",
                    key: "agilysis_authenticationCode",
                    type: "singleline",
                    placeholder: "Order Type ID Reference"
                },
                {
                    key: "agilysis_clientID",
                    type: "singleline",
                    placeholder: "Client ID"
                },
                {
                    key: "agilysis_soapURL",
                    type: "singleline",
                    placeholder: "SOAP URL"
                },
                {
                    key: "agilysis_tenderID",
                    type: "singleline",
                    placeholder: "Tender ID"
                },
                {
                    key: "agilysis_checkTypeID",
                    type: "singleline",
                    placeholder: "Check Type ID"
                },
                {
                    key: "agilysis_employeeID",
                    type: "singleline",
                    placeholder: "Employee ID"
                },
                {
                    key: "agilysis_priceLevel",
                    type: "singleline",
                    placeholder: "Price Level"
                },
                {
                    key: "agilysis_profitCenterID",
                    type: "singleline",
                    placeholder: "Profit Center ID"
                }
            ],
            values: {
                storeName: "",
                airportIdent: "",
                streetSideLocation: "",
                terminal: "",
                region: "",
                category: "",
                subCategory: "",
                currency: "",
                newCurrency: "",
                revenueCenterID: "",
                integrated: "",
                pos: "",
                omnivoresubpos: ""
            }
        };
    },
    computed: {
        settingsGroups() {
            let group = {
                name: "Store Information",
                sections: [
                    {
                        fields: [
                            {
                                key: "storeName",
                                display: "Exact name of store (customer facing)",
                                type: "singleline",
                                placeholder: "Store Name"
                            }
                        ]
                    },
                    {
                        fields: [
                            {
                                key: "region",
                                display: "Region/country",
                                type: "select",
                                placeholder: "Select",
                                options: ["USA", "USA1", "USA2"]
                            }
                        ]
                    },
                    {
                        fields: [
                            {
                                key: "airportIdent",
                                display: "Airport Information",
                                type: "singleline",
                                placeholder: "3 letter code"
                            },
                            {
                                key: "streetSideLocation",
                                display: "",
                                type: "singleline",
                                placeholder: "Street side location (optional)"
                            },
                            {
                                key: "terminal",
                                display: "",
                                type: "singleline",
                                placeholder: "Terminal"
                            }
                        ]
                    },
                    {
                        fields: [
                            {
                                key: "language",
                                display: "Language Support",
                                type: "singleline",
                                placeholder: "Language Support"
                            }
                        ]
                    },
                    {
                        fields: [
                            {
                                key: "category",
                                display: "Category",
                                type: "select",
                                placeholder: "Category",
                                options: ["Food", "Retail", "Services"]
                            },
                            {
                                key: "subCategory",
                                display: "",
                                type: "select",
                                placeholder: "Select",
                                options: this.subCategoryOptions,
                                hidden: this.values.category == ""
                            }
                        ]
                    },
                    {
                        fields: [
                            {
                                key: "currency",
                                display: "Currency",
                                type: "select",
                                placeholder: "Currency",
                                options: ["USD", "GBP", "SEK", "Pesos", "AUD", "New"]
                            },
                            {
                                key: "newCurrency",
                                display: "",
                                type: "singleline",
                                placeholder: "Currency Type",
                                hidden: this.values.currency != "New"
                            }
                        ]
                    },
                    {
                        fields: [
                            {
                                key: "revenueCenterID",
                                display: "Revenue Center ID for table numbers ",
                                type: "singleline",
                                placeholder: "Revenue Center ID"
                            }
                        ]
                    },
                    {
                        fields: [
                            {
                                key: "Grab API tender ID",
                                display: "Grab API tender ID",
                                type: "singleline",
                                placeholder: "Grab API tender ID"
                            }
                        ]
                    },
                    {
                        fields: [
                            {
                                key: "Grab API employee ID",
                                display: "Grab API employee ID",
                                type: "singleline",
                                placeholder: "Grab API employee ID"
                            }
                        ]
                    },
                    {
                        fields: [
                            {
                                key: "menuPriceLevel",
                                display: "Menu Price Level (if applicable) ",
                                type: "singleline",
                                placeholder: "Menu Price Level"
                            }
                        ]
                    }
                ]
            };
            let group2 = {
                name: "Point of Sale Information",
                expanded: true,
                sections: [
                    {
                        fields: [
                            {
                                key: "integrated",
                                display: "Point of Sale Integration",
                                type: "choice",
                                choices: ["Integrated", "Non-Integrated"],
                                infoText:
                  "Integrated – Your payment terminal speaks to your point of sale. <br>Non-integrated – Your payment terminal does not speak to your point of sale."
                            },
                            {
                                display:
                  "Thank you! Please reach out to support@getgrab for assistance with creating a Grab tender.",
                                type: "message",
                                color: "success",
                                hidden: this.values.integrated != "Non-Integrated"
                            },
                            {
                                key: "pos",
                                display: "",
                                type: "select",
                                placeholder: "POS",
                                options: [
                                    "Omnivore",
                                    "HMSHost",
                                    "Agilysis",
                                    "Vivonet",
                                    "Checkmate"
                                ],
                                hidden: this.values.integrated != "Integrated"
                            }
                        ]
                    },
                    ...this.posSections
                ]
            };
            return [group, group2];
        },
        posSections() {
            var fields = [];
            if (this.values.pos == "" || this.values.integrated == "Non-Integrated")
                return [];
            if (this.values.pos == "Omnivore") {
                fields = this.omnivoreFields;
            } else if (this.values.pos == "HMSHost") {
                fields = this.hmsHostFields;
            } else if (this.values.pos == "Agilysis") {
                fields = this.agilysisFields;
            }
            return [
                {
                    fields
                }
            ];
        },
        omnivoreFields() {
            let base = [
                {
                    key: "omnivoresubpos",
                    display: "Omnivore",
                    type: "select",
                    options: [
                        "Micros Symphony 1x",
                        "Micros Symphony 2x",
                        "Micros 3700",
                        "NCR Aloha",
                        "Brink"
                    ],
                    placeholder: "POS"
                },
                {
                    key: "omnivoreLocationID",
                    display: "Omnivore Location ID",
                    type: "singleline",
                    placeholder: "Omnivore Location ID"
                }
            ];

            if (this.values.omnivoresubpos == "Micros 3700") {
                return [
                    ...base,
                    {
                        key: "micros_menuPriceLevel",
                        display: "Menu Price Level",
                        placeholder: "Menu Price Level",
                        type: "singleline"
                    }
                ];
            }

            if (this.values.omnivoresubpos == "NCR Aloha") {
                return [
                    ...base,
                    {
                        key: "aloha_forcedModifier",
                        display: "Forced Modifiers",
                        type: "choice",
                        choices: ["On", "Off"]
                    }
                ];
            }

            return base;
        },
        subCategoryOptions() {
            if (this.values.category == "Food") {
                return [
                    "American",
                    "Bakery",
                    "Bar",
                    "BBQ",
                    "British",
                    "Cajun",
                    "Candy",
                    "Caribbean",
                    "Coffee & Drinks",
                    "Cuban",
                    "Deli",
                    "Desserts",
                    "French",
                    "Global",
                    "Greek",
                    "Healthy",
                    "Indian",
                    "Italian",
                    "Kosher",
                    "Latin",
                    "Mediterranean",
                    "Mexican",
                    "Middle Eastern",
                    "Peruvian",
                    "Pub",
                    "Seafood",
                    "Smoothies",
                    "Snacks",
                    "Wine Bar"
                ];
            } else if (this.values.category == "Retail") {
                return [
                    "Accessories",
                    "Art",
                    "Beauty",
                    "Books",
                    "Candy",
                    "Clothing",
                    "Duty Free",
                    "Electronics",
                    "Gifts",
                    "Luggage",
                    "News"
                ];
            } else if (this.values.category == "Services") {
                return ["Currency exchange", "Lounge", "Shoeshine", "Spa"];
            }
        }
    },
    created() {
        this.resolvePageTitle();
    },
    methods: {
        resolvePageTitle() {}
    }
};
</script>

<style lang="scss">
.settings-container {
  max-width: 75%;
}
</style>
